import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <hr />
      <p>Scandiweb Test assignment</p>
    </div>
  );
}
